import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Routes from "../routes"
import { useImpression } from "../analytics/utils"

const Agnostic = ({ location }) => {
  useImpression("Agnostic")
  return (
    <Layout location={location}>
      <Seo
        title="What it means to be agnostic"
        description="Hundreds of millions of people identify as atheist or agnostic. But what does it really mean to be agnostic, and how does it relate to the world's religions."
      />
      <h1>What it means to identify as Agnostic</h1>
      <p>
        Being an agnostic is becoming more and more common. People are waking
        up, as our friend Sam Harris might say. They are getting closer and
        closer to a realization that they're actually not certain God exists.
        They're growing more skeptical, especially as religious scandals like
        that of <Link to={Routes.GEORGE_PELL}>George Pell</Link> continue to be
        surface. Agnostics are, well, sort of like{" "}
        <Link to={Routes.ATHEISM}>atheists</Link>, but there are some key
        differences. Let's dig in.
      </p>
      <h2>What does it mean to be agnostic?</h2>
      <p>
        An agnostic is essentially someone who does not follow a specific
        religion, but is relatively open-minded about the possible exitence of a
        supernatrual being like god. Agnostics tend not to claim whether or not
        god exists.
      </p>
      <p>So where exactly did we get the word from?</p>
      <h2>Origin of the word</h2>
      <p>
        The term "agnostic" appeared in the 19th century from a man named Thomas
        Huxley, a biologist, speaker, and philosopher, and great supporter of
        Charles Darwin's "Origin of Species".
      </p>
      <h2>Who identifies as agnostic?</h2>
      <p>
        {/* TODO: Citation */}
        Studies have found that globally over 450-500 million people identify as
        either an atheist or agnostic. The exact numbers can be tough to asses,
        since agnostics are often counted along with atheist and non-religious
        groups. Furthermore, there are said to be a vast number of "religious"
        people who simply do not believe, but are pressured to by either their
        family, lifestyle, or simply out of fear of leaving the Church.
      </p>
      <p>
        A 2010 survey from Encyclopædia Britannica found that almost 10% of the
        world's population as non-religious or agnostic. [1]
      </p>
      <p>
        The Financial Times, back in 2006, polled the rate of agnosticism and
        found some staggering numbers [2]. Hopefully by{" "}
        {new Date().getFullYear()} these numbers will be dramatically higher.
      </p>
      <table>
        <tbody>
          <tr>
            <th>Country</th>
            <th>Rate of agnosticism</th>
          </tr>
          <tr>
            <td>United States</td>
            <td>14%</td>
          </tr>
          <tr>
            <td>Germany</td>
            <td>25%</td>
          </tr>
          <tr>
            <td>Italy</td>
            <td>20%</td>
          </tr>
          <tr>
            <td>Spain</td>
            <td>30%</td>
          </tr>
          <tr>
            <td>Great Britain</td>
            <td>35%</td>
          </tr>
          <tr>
            <td>France</td>
            <td>32%</td>
          </tr>
        </tbody>
      </table>
      <h4>Sources</h4>
      <ol>
        <li>
          "Religion: Year in Review 2010: Worldwide Adherents of All Religions".
          Encyclopædia Britannica. Encyclopædia Britannica Inc. Archived from
          the original on July 2, 2014. Retrieved November 21, 2013.
        </li>
        <li>
          "Religious Views and Beliefs Vary Greatly by Country, According to the
          Latest Financial Times/Harris Poll". Financial Times/Harris
          Interactive. December 20, 2006. Archived from the original on July 23,
          2013. Retrieved April 9, 2011.
        </li>
      </ol>
    </Layout>
  )
}

export default Agnostic
